body {
  background-color: #fff;
}

.carousel-control {
  width: 4%;
}

.carousel-control.left {
  margin-left: 15px;
  background-image: none;
}

.carousel-control.right {
  margin-left: 15px;
  background-image: none;
}

@media (max-width: 767px) {
  .carousel-inner .next {
    left: 100%;
  }
  .carousel-inner .prev {
    left: -100%;
  }
  .carousel-inner .active.left {
    left: -100%;
  }
  .active > div {
    display: none;
  }
  .active > div:first-child {
    display: block;
  }
}

@media (min-width: 767px) and (max-width: 992px) {
  .carousel-inner .next {
    left: 50%;
  }
  .carousel-inner .prev {
    left: -50%;
  }
  .carousel-inner .active.left {
    left: -50%;
  }
  .active > div {
    display: none;
  }
  .active > div:first-child {
    display: block;
  }
  .active > div:first-child + div {
    display: block;
  }
}

@media (min-width: 992px) {
  .carousel-inner .next {
    left: 25%;
  }
  .carousel-inner .prev {
    left: -25%;
  }
  .carousel-inner .active.left {
    left: -25%;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  div.product .info {
    max-width: 210px;
  }
}

div.product .info {
  position: absolute;
  bottom: 0;
  max-width: 86%;
  text-overflow: ellipsis;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
  max-height: 6em;
  font-size: 1.1em;
  line-height: 1.8em;
  padding: 8px 0;
  overflow: hidden;
}

div.product .info h4 {
  margin: 0;
  padding: 0;
  display: block;
  padding: 0 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 4.8em;
  font-size: .8em;
  line-height: 1em;
  line-height: 1.2em;
  color: #ddd;
}

div.product .info h6 {
  margin: 0;
  padding: 0;
  margin-top: 10px;
}

div.product .info small {
  margin: 0;
  padding: 0;
}

div.product .info .more_info {
  padding: 0 9px;
  height: 0;
  overflow: hidden;
  transition: height .3s;
}

div.product:hover .info .more_info {
  height: 3em;
  transition: height .3s;
}

.flag {
  display: inline-block;
  width: 32px;
  margin-top: -10px;
  height: 32px;
  background: url("/img/flags.png") no-repeat;
}

.flag.flag-us {
  background-position: 0 -32px;
}

.flag.flag-ru {
  background-position: -32px 0;
}

.flag.flag-am {
  background-position: 0 0;
}

.list-group-item span {
  text-transform: capitalize;
}

.single_product {
  padding-top: 40px;
}

.main_picture {
  border: 1px solid #DDD;
  width: 100%;
}

.pictures .col-md-4 {
  border: 1px solid #DDD;
}

.pictures .col-md-4 a img {
  opacity: 0.6;
  transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -moz-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
}

.pictures .col-md-4 a img:hover {
  opacity: 1;
  cursor: pointer;
}

.product_info .title {
  font-size: 20px;
}

.product_info .info {
  font-size: 16px;
}

.product_info .price {
  font-size: 22px;
}

.product_info .status {
  color: #74DF00;
  font-size: 20px;
  margin-top: 10px;
}

.input_qty {
  display: block;
  float: left;
  max-width: 50px;
  vertical-align: middle;
  line-height: 29px;
  margin-right: 30px;
}
