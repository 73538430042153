.article-list {
	color: #313437;
	background-color: #fff;
	p {
		color: #7d8285;
	}
	h2 {
		font-weight: bold;
		margin-bottom: 40px;
		padding-top: 40px;
		color: inherit;
	}
	.intro {
		font-size: 16px;
		max-width: 500px;
		margin: 0 auto;
		p {
			margin-bottom: 0;
		}
	}
	.articles {
		padding-bottom: 40px;
	}
	.product {
		padding-top: 50px;
		min-height: 100px;
		text-align: center;
		.name {
			font-weight: bold;
			font-size: 16px;
			margin-top: 20px;
			color: inherit;
		}
		.description {
			font-size: 14px;
			margin-top: 15px;
			margin-bottom: 0;
		}
		.action {
			font-size: 24px;
			width: 24px;
			margin: 22px auto 0;
			line-height: 1;
			display: block;
			color: #4f86c3;
			opacity: 0.85;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: opacity 0.2s;
			text-decoration: none;
			&:hover {
				opacity: 1;
			}
		}
	}
}
@media(max-width:767px) {
	.article-list h2 {
		margin-bottom: 25px;
		padding-top: 25px;
		font-size: 24px;
	}
}

