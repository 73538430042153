.custom-header {
	background: #292c2f;
	padding: 20px 40px;
	//Instead of the line below you could use @include box-sizing($bs)
	box-sizing: border-box;
	border: 0;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0;
	.badge {
		position: absolute;
		top: 4px;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 2px;
		font-size: 11px;
		color: #fff;
		background-color: #2a6a92;
		padding: 2px 4px;
		font-weight: normal;
	}
	a.navbar-brand.navbar-link {
		color: #fff;
		font: normal 28px Cookie, Arial, Helvetica, sans-serif;
		line-height: 0.5;
		margin-right: 40px;
		background: #292c2f;
		span {
			color: #bc40df;
		}
	}
	ul.nav.navbar-nav {
		&.links li a {
			font: 14px Arial, Helvetica, sans-serif;
			opacity: 0.9;
			text-decoration: none;
			color: #fff;
			background: #292c2f;
			border: 0;
		}
		li a:hover {
			opacity: 1;
		}
		&.navbar-right .dropdown li a {
			font: bold 13px Arial, Helvetica, sans-serif;
		}
	}
	li.dropdown {
		background: #3a3c3e;
		&.open {
			background: #3a3c3e;
		}
		.dropdown-toggle {
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 2px;
			color: #fff;
			padding: 8px 14px;
			background: #3a3c3e;
			opacity: 0.9;
			border: 0;
			margin: 0;
			&:focus {
				background: #3a3c3e;
				color: #fff;
			}
			&:hover {
				background: #3a3c3e;
				color: #fff;
			}
			&:active {
				background: #3a3c3e;
				color: #fff;
			}
		}
	}
	.dropdown ul {
		background-color: #3a3c3e;
		border: 0;
		min-width: 140px;
		li {
			background-color: #3a3c3e;
			padding: 7px;
			text-align: center;
			a {
				background: #3a3c3e;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 2px;
				color: #fff;
				padding: 0;
				&:active {
					background: #3a3c3e;
				}
				&:focus {
					background: #3a3c3e;
				}
				&:hover {
					background: #3a3c3e;
				}
			}
			&.active a {
				color: #e9ac09 !important;
			}
		}
	}
}
img.dropdown-image {
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 50%;
	width: 32px;
	margin-left: 10px;
}