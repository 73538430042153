body {
	background-color: #fff;
}

.carousel-control {
	width: 4%;
	&.left {
		margin-left: 15px;
		background-image: none;
	}
	&.right {
		margin-left: 15px;
		background-image: none;
	}
}
@media(max-width: 767px) {
	.carousel-inner {
		.next {
			left: 100%;
		}
		.prev {
			left: -100%;
		}
		.active.left {
			left: -100%;
		}
	}
	.active > div {
		display: none;
		&:first-child {
			display: block;
		}
	}
}
@media(min-width: 767px) and(max-width: 992px ) {
	.carousel-inner {
		.next {
			left: 50%;
		}
		.prev {
			left: -50%;
		}
		.active.left {
			left: -50%;
		}
	}
	.active > div {
		display: none;
		&:first-child {
			display: block;
			+ div {
				display: block;
			}
		}
	}
}
@media(min-width: 992px ) {
	.carousel-inner {
		.next {
			left: 25%;
		}
		.prev {
			left: -25%;
		}
		.active.left {
			left: -25%;
		}
	}
}
@media(min-width: 768px ) and(max-width: 1200px ) {
	div.product .info {
		max-width: 210px;
	}
}
div.product {
	.info {
		position: absolute;
		bottom: 0;
		max-width: 86%;
		text-overflow: ellipsis;
		color: #fff;
		background-color: rgba(0, 0, 0, 0.4);
		max-height: 6em;
		font-size: 1.1em;
		line-height: 1.8em;
		padding: 8px 0;
		overflow: hidden;
		h4 {
			margin: 0;
			padding: 0;
			display: block;
			padding: 0 9px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			max-height: 4.8em;
			font-size: .8em;
			line-height: 1em;
			line-height: 1.2em;
			color: #ddd;
		}
		h6 {
			margin: 0;
			padding: 0;
			margin-top: 10px;
		}
		small {
			margin: 0;
			padding: 0;
		}
		.more_info {
			padding: 0 9px;
			height: 0;
			overflow: hidden;
			transition: height .3s;
		}
	}
	&:hover .info .more_info {
		height: 3em;
		transition: height .3s;
	}
}

.flag {
    display: inline-block;
    width: 32px;
    margin-top: -10px;
    height: 32px;
    background: url('/img/flags.png') no-repeat;
}


.flag.flag-us {
    background-position: 0 -32px;
}

.flag.flag-ru {
    background-position: -32px 0;
}

.flag.flag-am {
    background-position: 0 0;
}

.list-group-item span {
	text-transform: capitalize;
}

.single_product {
	padding-top: 40px;
}

.main_picture {
	border: 1px solid #DDD;
	width: 100%;
}

.pictures {
	.col-md-4 {
		border: 1px solid #DDD;
		a {
			img {
				opacity: 0.6;
				transition: all .2s ease-in;
				-o-transition: all .2s ease-in;
				-moz-transition: all .2s ease-in;
				-webkit-transition: all .2s ease-in;
				&:hover{
					opacity: 1;
					cursor: pointer;
				};
			}
		}
	}
}

.product_info {
	.title {
		font-size: 20px;
	}
	.info {
		font-size: 16px;
	}
	.category {

	}
	.price {
		font-size: 22px;
	}
	.status {
		color: #74DF00;
		font-size: 20px;
		margin-top: 10px;
	}
}
.input_qty {
	display: block;
	float: left;
	max-width: 50px;
	vertical-align: middle;
	line-height: 29px;
	margin-right: 30px;
}
